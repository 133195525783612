<template>
  <p class="title font-weight-medium black--text">{{title}}</p>
</template>

<script>
export default {
  name: 'FormSectionTitle',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
